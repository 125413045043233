<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/user/userlist')" class="el-icon-close"></i>
      </div>

      <div class="pt20"></div>
    <div class="form">
      <el-form ref="form" :model="form" label-width="180px">
        <el-form-item label="账号" :required="true" >
          <el-input v-model="form.account"></el-input>
        </el-form-item>
  
        <el-form-item :required="true" label="密码">
          <el-input placeholder="请输入密码" v-model="form.password" show-password></el-input>
          <div style="position: absolute;top:0;right:-325px;width:320px;color: red;font-family: fangsong;">包含大、小写英文字母+数字，最少8位，最多16位</div>
        </el-form-item>
        <el-form-item :required="true" label="确认密码">
          <el-input placeholder="确认密码" v-model="form.password_confirmation" show-password></el-input>
          <div style="position: absolute;top:0;right:-325px;width:320px;color: red;font-family: fangsong;">包含大、小写英文字母+数字，最少8位，最多16位</div>
        </el-form-item>

        <el-form-item :required="true" label="手机号">
          <el-input  v-model="form.phone"></el-input>
        </el-form-item>

        <el-form-item :required="true" label="邮箱">
          <el-input  v-model="form.email"></el-input>
        </el-form-item>
        
        <el-form-item :required="true" label="姓名">
          <el-input  v-model="form.name"></el-input>
        </el-form-item>
        
        <el-form-item :required="true" label="选择角色">
          <el-select multiple
    collapse-tags v-model="form.role" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
          <el-button @click="goNav('/user/userlist')" >取消</el-button>
        </el-form-item>
      </el-form>
    </div>

      <div class="pt20"></div>

    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
import {encrypt} from '../../lib/secret'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      form: {
          account: '',
          password: '',
          password_confirmation: '',
          phone: '',
          email: '',
          name: '',
        },
        options:[],
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
     onSubmit() {
      let _this = this;
      let postdata = JSON.parse(JSON.stringify(this.form));
     if(postdata.account == '' || postdata.password == '' || postdata.password_confirmation == '' || postdata.role == '' || postdata.phone == ''|| postdata.email == ''|| postdata.name == ''){
        _this.$message.error('请填写必填项');
        return
     }
     if(postdata.password != postdata.password_confirmation){
        _this.$message.error('密码不一致');
        return
      }
     var boo = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/.test(postdata.password);
     if(!boo){
        _this.$message.error('密码至少包含大写字母，小写字母，数字，且不少于8位，不大于16位!');
        return
     }
     
     for(let i in postdata){
       if(postdata[i].constructor !== Array){
         postdata[i] = encrypt(postdata[i])
       }
     }
      axios.put(config.user,postdata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.goNav('/user/userlist');
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },
    initoptions(){
        let _this = this;
        axios.get(config.role,{params:{page:1,status:1,limit:999}})
        .then(function (response) {
            if(response.data.code == 200){
              fiflter(response.data.data.data);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      function fiflter(list){
        let arr = [];
        for(let i in list){
          let obj = {};
          obj.label = list[i].name;
          obj.value = list[i].role_id;
          arr.push(obj);
         }
          _this.options = arr;
        }
    }
  },
  created(){
      this.initoptions();
  }
}
</script>
<style scoped>
.form{
    width: 500px;
    margin-left: 200px;
}
.el-icon-close:before{
  font-size: 24px;
}
</style>
